<template>
  <b-row v-if="show_layout">
    <div class="position-relative w-100">
      <video class="background-video-proposals" preload="auto" loop="" autoplay="" muted="" playsinline="">
        <source :src="require('@/assets/images/dashboard/proposals/background-home-proposals.mp4')">
      </video>
      <div class="landing-cover mt-3">
        <div class="d-flex flex-column align-items-center">
          <h3 class="text-title fw-semi-bold text-white text-center mb-5 mb-sm-4 mx-2">
            <transition name="fade" @leave="leaveTransition">
              <span :key="current_word" class="position-relative">
                <span id="current-word">
                  {{ words_main_titles[current_word] }} 
                </span>
              </span>
            </transition>
            {{ $t('creator.hireCreators') }}
          </h3>
          <div class="position-relative container-search">
            <b-form-input
              class="input-search-proposal"
              :placeholder="!user_data || (user_data.main_group.name !== 'creator') ?  $t('creator.placeholder_suggestions_brand') : $t('creator.placeholder_suggestions_creator')"
              v-model="search"
              @keyup.enter="searchQuery()"
              trim
              @focus="show_suggestions = true; update_suggestions = !update_suggestions"
              @blur="blurActionInput"
              autocomplete="off"
            > 
            </b-form-input>
            <b-icon
              class="icon-search"
              icon="search"
              @click="searchQuery"
            ></b-icon>
            <suggestions-container 
              :show_suggestions="show_suggestions"
              :key="update_suggestions"
              :list_selected="button_Selected"
            />
          </div>
          <div>
            <b-button pill class="button-create avenir-medium" variant="button-create" @click="$router.push({name: 'pages-account-setting', params: { section: 'membership' } })">
              {{ $t('creator.createPro') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-row class="m-0 p-1 p-md-3 mx-auto container-results-proposals w-100">
      <div class="container-loader-searcher loader-table-cards" v-if="!loaded">
      <b-spinner class="spinner-table-searcher"></b-spinner>
    </div>
      <b-col class="w-100">
        <div class="w-100 d-flex justify-content-between">
          <span class="text-proposals">{{ $t('creator.proposals') }}</span>
          <b-link @click="$router.push({name: 'search_proposal'})">
            <span class="avenir-medium">{{ $t('creator.viewAlls') }}</span>
          </b-link>
        </div>

        <component :is="vue_horizontal" responsive class="horizontal my-2" :displacement="0.3" snap="center" v-if="loaded">
          <card-category
            v-for="(item, index) in results" :key="index"
            :proposal="item"
            :width="'300px'"
            class="mr-1"
          />
        </component>
      </b-col>
      <b-col class="col-12">
        <div class="mb-2">
          <span class="text-proposals">{{ $t('creator.proposalsBynet') }}</span>
        </div>
        <component :is="vue_horizontal" responsive class="horizontal" :displacement="0.3" snap="center">
          <card-network  v-for="(item, index) in network"
            :key="index"
            :net="item"
            :icon="getIconWhite(item)"
            :img="imgNetwork(item)"
          />
        </component>
      </b-col>
      <b-col class="col-12 px-1 mb-2">
        <div class="box-casting-calls">
          <div class="col-12 col-md-3 mb-1 mb-md-0">
            <h1>{{ $t('dashboard.campaigns') }}</h1>
            <p class="avenir-medium">{{ $t('creator.wantWork') }}</p>
            <b-button variant="light" class="avenir-medium" @click="$router.push({name: 'casting_calls'})">{{ $t('creator.viewMore') }}</b-button>
          </div>
          <div class="col-12 col-md-9">
            <component :is="vue_horizontal" responsive class="horizontal horizontal-gray-casting-calls" :displacement="0.3" snap="end" v-if="loaded_casting_calls">
              <card-casting-call v-for="casting_call in casting_calls" :key="casting_call.uuid" :casting_call="casting_call"></card-casting-call>
            </component>
          </div>
        </div>
      </b-col>
      <b-col class="col-12 px-1">
        <b-row class="w-100 m-0">
          <card-marketing class="col-12 col-lg-6 p-0 pr-lg-1 mb-2 mb-lg-0"
            :textBadge="$t('creator.marketingSoftware')"
            :textInfo="$t('creator.manageAll')"
            :textButton="$t('creator.getStarted')"
            :textLink="$t('creator.findAbout')"
            :img="imgNetwork('pinterest')"
            :reference="'https://brandme.la/solicitar-propuesta/'"
            :reference_link="'https://brandme.la/solicitar-propuesta/'"
          >
          </card-marketing>
          
          <card-marketing class="col-12 col-lg-6 p-0 pl-lg-1"
            :colorCard="'card-secondary'"
            :textBadge="$t('creator.fullService')"
            :textInfo="$t('creator.strategyNeed')"
            :textButton="$t('creator.requestProposals')"
            :textLink="$t('creator.learMore')"
            :link="$t('creator.managedService')"
            :img="imgNetwork('food')"
            :reference="'https://brandme.la/solicitar-propuesta/'"
            :reference_link="'https://brandme.la/solicitar-propuesta/'"
          >
          </card-marketing>
        </b-row>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BIcon,
  BButton,
  BLink,
  BSpinner,
} from "bootstrap-vue";
import service from "@/services/proposals";
import casting_calls_services from '@/services/casting_calls';
import cardCategory from "@/views/pages/proposals/cardCategory.vue";
import cardCastingCall from '@/views/components/casting-calls/cardCastingCall.vue';
import cardNetwork from "@/views/pages/proposals/cardNetwork.vue";
import { getIconWhite } from '@/libs/utils/icons';
import { getUserData } from '@/libs/utils/user';
import { getMotivationalPhrase } from '@/libs/utils/others';
import { getUrlCategory } from '@/libs/utils/urls';


export default {
  components: {
    BSpinner,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    cardCategory,
    cardCastingCall,
    cardNetwork,
    cardMarketing: () => import('@/views/components/proposals/cardMarketing.vue'),
    suggestionsContainer: () => import('@/views/components/proposals/suggestionsContainer.vue'),
  },
  data() {
    return {
      getIconWhite,
      words_main_titles: [this.$t('creator.pioner'), this.$t('creator.experts'), this.$t('creator.leader')],
      current_word: 0,
      interval_word: null,
      interval_loading: null,
      search: "",
      results: {},
      network: ['tiktok', 'instagram','youtube','facebook','linkedin','twitch','twitter','spotify','pinterest','blog','snapchat'],
      show_layout: false,
      show_suggestions: false,
      update_suggestions: false,
      casting_calls: [],
      loaded: false,
      loaded_casting_calls: false,
      user_data: getUserData(),
      button_Selected: {
        selected: false,
        type_button: '',
        list_button: [],
      },
      vue_horizontal: null,
    };
  },
  created() {
    window.addEventListener('scroll', this.scrollActionContainer);
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default
    const body_back = document.getElementsByTagName('body')[0];
    body_back.style.backgroundColor = "white";
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    const body = document.querySelector('div.app-content.content');
    const header_navbar_shadow = document.getElementsByClassName('header-navbar-shadow')[0];
    const navbar_brandme = document.getElementsByClassName('header-navbar')[0];
    const nav_inner = document.getElementsByClassName('navbar-container')[0];
    header_navbar_shadow.style.setProperty('display', 'none');
    body.style.setProperty('padding', '0px',  'important');
    navbar_brandme.classList.add('tranparent-background-nav')
    nav_inner.classList.add('tranparent-background-nav')
    this.show_layout = true;
    window.addEventListener("resize", this.handleEventResize);
    this.getAll();
    this.getCastingCalls();
    
    // INTERVAL TO ANIMATION MAIN TITLE OF HOME SEARCH -- TO NICE TO HAVE
    // this.interval_word = setInterval(() => {
    //   this.current_word === 2 ? this.current_word = 0 : this.current_word += 1;
    // }, 5000);
    // =====================================
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollActionContainer);
    const body_back = document.getElementsByTagName('body')[0];
    body_back.removeAttribute('style');
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    const body = document.querySelector('div.app-content.content');
    const header_navbar_shadow = document.getElementsByClassName('header-navbar-shadow')[0];
    const navbar_brandme = document.getElementsByClassName('header-navbar')[0];
    const nav_inner = document.getElementsByClassName('navbar-container')[0];
    navbar_brandme.classList.remove('tranparent-background-nav')
    navbar_brandme.classList.remove('out-animation-nav')
    navbar_brandme.classList.remove('inut-animation-nav')
    nav_inner.classList.remove('tranparent-background-nav')
    header_navbar_shadow.removeAttribute('style')
    body.removeAttribute('style');
    window.removeEventListener("resize", this.handleEventResize);
    clearInterval(this.interval_word)
  },
  methods: {
    leaveTransition() {
      document.getElementById('current-word').classList.add('leave')
    },
    getCastingCalls() {
      casting_calls_services.getCastingCalls({}, 8).then((response) => {
        this.casting_calls = response.results;
        this.loaded_casting_calls = true;
      })
    },
    scrollActionContainer(s) {
      const navbar_brandme = document.getElementsByClassName('header-navbar')[0];
      const nav_inner = document.getElementsByClassName('navbar-container')[0];
      if (s.target.documentElement.scrollTop > 100) {
        navbar_brandme.classList.remove('in-animation-nav')
        navbar_brandme.classList.add('out-animation-nav')
        setTimeout(() => {
          nav_inner.classList.remove('tranparent-background-nav')
          navbar_brandme.classList.remove('tranparent-background-nav')
        }, 500);
      } else {
        navbar_brandme.classList.remove('out-animation-nav')
        navbar_brandme.classList.add('in-animation-nav')
        setTimeout(() => {
          nav_inner.classList.add('tranparent-background-nav')
          navbar_brandme.classList.add('tranparent-background-nav')
        }, 500);
      }
    },
    
    searchQuery() {
      if (!this.user_data || (this.user_data.main_group.name !== 'creator')) {
        if (this.search.length > 0) this.$router.push({name: 'search_proposal', query: {query: this.search}});
        this.$root.$emit('update_proposals');
      } else if (this.user_data.main_group.name === 'creator') {
        if (this.search.length > 0) this.$router.push({name: 'casting_calls', query: {query: this.search}});
        this.$root.$emit('update_casting_calls');
      }
      this.$root.$emit('update_search_query', this.search);
    },
    handleEventResize() {
      const navbar_invisible = document.querySelector('.header-navbar.navbar-shadow');
      navbar_invisible.style.setProperty('background', 'none', 'important');
    },
    getAll() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });
      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      service.searchProposals({}, 10).then((response) => {
        this.results = response.results
        this.loaded = true;
        setTimeout(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        }, 500);
      });
    },
    blurActionInput() {
      setTimeout(() => {        
        this.show_suggestions = false;
        this.update_suggestions = !this.update_suggestions;
      }, 1000);
    },
    imgNetwork(category) {
      return getUrlCategory(category)
    },
  },
};
</script>
<style>
.tranparent-background-nav {
  background: none !important;
  background-color: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.in-animation-nav {
  animation: nav-animation 1s;
}
.out-animation-nav {
  animation: nav-animation-2 1s;
}
@keyframes nav-animation {
  0% { transform: translateY(0px);}
  50% {transform: translateY(-100px);}
  100% {transform: translateY(0px);}
}
@keyframes nav-animation-2 {
  0% { transform: translateY(0px);}
  50% {transform: translateY(-100px);}
  100% {transform: translateY(0px);}
}
.leave {
  position: absolute;
}
</style>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 4em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.box-casting-calls {
  background-color: #ececec;
  padding: 2em;
  border-radius: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background: none !important;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white) !important;
  padding-left: 24px !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-prev {
  background: linear-gradient(to right, #ececec 0, #ffffff00 66%, #ffffff00) !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, #ececec 66%, #ececec) !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}
.link-view-all {
  font-weight: 400;
  color: #55a6c4 !important;
}
.text-proposals {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}
.container-proposals {
  display: flex;
  justify-content: space-between !important;
  margin: 67px 10px 10px 8px;
}
.container-cards {
  display: flex;
  justify-content: space-between !important;
}
.button-create {
  margin: 22px;
  background: rgba(248, 249, 250, 0.2) !important;
  color: #f8f9fa;
}
.button-create:hover {
  margin: 22px;
  background: rgba(248, 249, 250, 0.792) !important;
}
.icon-search {
  position: absolute;
  color: #6c757d;
  z-index: 8;
  margin-top: -41px;
  margin-left: 3px;
  font-size: 40px;
  cursor: pointer;
  padding: 8px;
}
.container-search .input-search-proposal { 
  border-radius: 1.357rem;
  padding-left: 40px;
  width: 550px;
  height: 45px;
}
.text-title {
  position: relative;
  font-size: calc(1.409375rem + 1.9125vw);
  font-weight: bold;
  line-height: 1.2;
}
.landing-cover {  
  position: absolute;
  top: 8.5rem;
  width: 100%;
  background: transparent;
}
.background-video-proposals {
  width: 100%;
  height: 440px;
  object-fit: fill;
}
@media (max-width: 615px) {
 .container-search .input-search-proposal{ 
   display: flex;
   width: 100%;
 }
 @media (max-width: 459px) {
 .container-cards { 
   justify-content: center !important;
  }
 }
}
.container-results-proposals {
  max-width: 1440px;
}
.card-provisional {
  /* width: 100px !important; */
  background-color: red;
  padding: 1em;
  margin: 1em;
}
</style>